
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import moment from "moment";
import TableTab from "@/components/kt-datatable/TableTab.vue";

export default defineComponent({
  name: "DataTable",

  components: {
    TableTab,
  },
  setup() {
    const pageheader = "Projected Consumption, Cost and CO2 - Electricity";
    let accountId = store.getters.AccountId;
    const sumCo2 = ref(0.0);
    const sumCost = ref(0.0);
    const sumKwh = ref(0.0);
    const SiteDetails = ref();
    const fromDate = ref(new Date());
    const toDate = ref(new Date());
    const addition = ref(false);
    const removal = ref(false);
    const isViewClicked = ref(false);
    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }

    const TableHeaders = ref<Array<Tableheader>>();
    const OriginalTableHeaders = ref<Array<Tableheader>>();
    const tableValues = ref([]);
    const colnames = ref();
    const disableDates = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate()
      );
      return selectedDate < currentDate;
    };

    const series = reactive([{}]);
    const labels = ref([""]);
    const eboptions = {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
        },
        stroke: {
          show: true,
          lineCap: "butt",
          colors: undefined,
          width: 3,
          dashArray: 0,
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: false,
          //enabledOnSeries: [1],
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("MMM YYYY");
            },
            style: {
              colors: "#9899ac",
            },
          },

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: [
          {
            title: {
              text: "Consumption (kWh) and CO₂ (kgCO₂e)",
            },
          },
          {
            opposite: true,
            title: {
              text: " Cost (£) ",
            },
          },
        ],
      },
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs(
        " Projected Consumption, Cost and CO2 - Electricity",
        ["NA"]
      );
      addition.value = false;
      removal.value = false;
    });

    async function getContent() {
      if (removal.value != true) {
        addition.value = true;
      }
      loading.value = true;
      const fromdateval: any = fromDate.value;
      const toDateval: any = toDate.value;
      let domainName = store.getters.DomainName.toLowerCase();

      const accountInfo: any = {
        accountid: accountId,
        fromdate: moment(fromdateval).format("YYYY-MM-DD 00:00:00"),
        todate: moment(toDateval).format("YYYY-MM-DD 00:00:00"),
        locations: 0,
        uplift: 0,
        DomainName: domainName,
      };
      isViewClicked.value = true;
      ApiService.post("api/projectedconsumption/pc_report", accountInfo)
        .then((resp) => {
          series.splice(0);
          sumCo2.value = resp.data.summarydetails.co2;
          sumCost.value = resp.data.summarydetails.cost;
          sumKwh.value = resp.data.summarydetails.usage;

          // SiteDetails.value = resp.data.siteDetails;
          TableHeaders.value = resp.data.detailsheader.map((a) => ({
            name: a.header,
            key: a.header,
            sortable: true,
          }));
          colnames.value = resp.data.detailsheader.map((a) => a.header);
          OriginalTableHeaders.value = resp.data.detailsheader.map((a) => ({
            name: a.header,
            key: a.header,
            sortable: true,
          }));

          tableValues.value = resp.data.dsiteDetails.map((a) => a);

          labels.value = resp.data.summary.map((a) => a.yearsmonthsum);
          series.push({
            name: "Consumption KWh and co2 (KgCox)",
            type: "column",
            data: resp.data.summary.map((a) => [
              new Date(`${a.years}-${a.monthid}-01`),
              a.usage,
            ]),
          });
          series.push({
            name: "Co2 (Kgco2)",
            type: "line",
            data: resp.data.summary.map((a) => [
              new Date(`${a.years}-${a.monthid}-01`),
              a.co2,
            ]),
          });
          series.push({
            name: "Cost ",
            type: "line",
            data: resp.data.summary.map((a) => [
              new Date(`${a.years}-${a.monthid}-01`),
              a.cost,
            ]),
          });
          loading.value = false;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }

    function openAssetAddition() {
      console.log("clicked");
      addition.value = true;
      removal.value = false;
    }
    function openAssetRemoval() {
      addition.value = false;
      removal.value = true;
    }

    return {
      pageheader,
      labels,
      series,
      getContent,
      SiteDetails,
      TableHeaders,
      sumCo2,
      sumCost,
      sumKwh,
      colnames,
      fromDate,
      toDate,
      openAssetAddition,
      openAssetRemoval,
      addition,
      removal,
      tableValues,
      eboptions,
      loading,
      OriginalTableHeaders,
      disableDates,
      isViewClicked,
    };
  },
});
